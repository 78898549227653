<template>
  <v-container fluid>
    <v-row justify="center" class="mt-3">
      <v-container style="max-width: 1280px">
        <v-card >
          <v-card-title>
            <v-row dense>
              <v-col>
                <div class="title-self"><b>買取方法</b></div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <p class="body-self">郵送買取と店頭買取</p>
                <a style="font-size: 16px;" href="https://www.kaitoriakiba.com:82/kaitorisheet.pdf" target="_blank"> <v-icon>mdi-download-box-outline</v-icon>買取申込書</a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card >
        <v-card class="mt-5">
          <v-card-title>
            <v-row dense>
              <v-col>
                <p class="title-self"><b>郵送買取</b></p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <p class="body-self"><b>STEP1 お問い合せ</b></p>
                <p class="body-self">商品の査定価格をお電話・メール・LINEにてご確認下さい。無料で査定させて頂きます。</p>
                <br>
                <p class="body-self"><b>STEP2 商品の発送</b></p>
                <p class="body-self">商品の発送について</p>
                <p class="body-self">➀買取申込書とご本人様確認書類（運転免許証、健康保険証、パスポート等）のコピーを買取商品と同梱して下さい。</p>
                <p class="body-self">➁下記住所へ商品の発送をお願い致します。</p>
                <br>
                <p class="body-self">〒101-0023</p>
                <p class="body-self">東京都千代田区神田松永町11</p>
                <p class="body-self">AT第一ビル5階</p>
                <p class="body-self">買取ミナモト</p>
                <p class="body-self">担当 三石宛</p>
                <p class="body-self">03-6262-9897</p>
                <br>
                <p class="body-self"><b>STEP3 お支払い</b></p>
                <p class="body-self">到着後に商品の状態を確認させて頂きます。</p>
                <p class="body-self">商品の数量・動作・状態など査定後、振込にてスピード支払い致します。</p>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-5">
          <v-card-title>
            <v-row>
              <v-col>
                <p class="title-self"><b>店頭買取</b></p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <p class="body-self"><b>STEP1 お問合せ</b></p>
                <p class="body-self">商品の査定価格をお電話・メール・LINEにてご確認下さい。無料で査定させて頂きます。</p>
                <p class="body-self">※店舗に直接ご来店頂いても問題ございません。</p>
                <br>
                <p class="body-self"><b>STEP2 査定・お支払い</b></p>
                <p class="body-self">商品の数量・動作・状態など査定後、商品代金を現金でお支払い致します。</p>
                <p class="body-self">商品と一緒に、以下の必要書類をお持ち下さい。</p>
                <p class="body-self">本人確認書類（運転免許証、健康保険証、パスポート等） ※買取譲渡申込書作成時に必要です。</p>
                <p class="body-self">当社営業時間中（10：30~19：00）にお持ち込み下さい。</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>


export default {
  name: 'BuyMethods',

  data: () => ({}),
  async mounted() {

  },
  methods: {}
};
</script>
<style>

.title-self {
  font-size: 20px;
}

.body-self {
  font-size: 16px;
}

</style>
